import React from 'react';
import { Link } from 'gatsby';

export default function Logo() {
  return (
    <Link to="/" className="inline-block py-2 text-center">
      <p className="text-xs text-green-500 mb-0 font-light font-serif ">
        Mobile Heilpädagogische Praxis
      </p>
      <h1 className="text-white text-lg font-bold -mt-1">
        Marianne Marschollek
      </h1>
    </Link>
  );
}
