import React from "react";
import PropTypes from "prop-types";

function Container({ children }) {
  return (
    <div className="container max-w-8xl px-4 py-8 mx-auto md:px-8 md:py-16">
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.element,
};

export default Container;
