import React from 'react';
import { Link } from 'gatsby';

import Logo from '../misc/Logo';

function Header() {
  return (
    <header className="bg-green-800 px-4 py-4 fixed z-10 w-full">
      <div className="md:max-w-6xl md:mx-auto md:flex md:items-center md:justify-between">
        <div className="flex justify-between items-center">
          <Logo />
          <div className="inline-block cursor-pointer md:hidden">
            <div className="bg-green-900 w-8 mb-2" style={{ height: 2 }}></div>
            <div className="bg-green-900 w-8 mb-2" style={{ height: 2 }}></div>
            <div className="bg-green-900 w-8" style={{ height: 2 }}></div>
          </div>
        </div>

        <div>
          <div className="hidden md:block">
            <Link
              to="/beratung-diagnostik"
              className="inline-block py-1 md:py-4 text-green-200 hover:text-gray-100 mr-6 "
            >
              Beratung und Diagnostik
            </Link>
            <Link
              to="/unterstützung-förderung"
              className="inline-block py-1 md:py-4 text-green-200 hover:text-gray-100 mr-6"
            >
              Unterstützung und Förderung
            </Link>
          </div>
        </div>
        <div className="hidden md:block">
          <a
            href="mailto:marianne@mobile-heilpaedagogik.de"
            className="inline-block py-2 px-4 text-green-900 bg-white hover:bg-gray-100 rounded-lg"
          >
            Kontakt
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
