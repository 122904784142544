import React from 'react';
import { Link } from 'gatsby';

import Logo from '../misc/Logo';

export default function Footer() {
  return (
    <footer className="flex justify-center px-4 text-gray-100 bg-green-1000 t-8">
      <div className="container py-6">
        <div className="py-6">
          <h1 className="text-center text-lg font-bold lg:text-2xl">
            &quot;Wenn ich mich mit einem Kind beschäftige, habe ich zwei
            Empfindungen: <br />
            Zuneigung für das, was es heute ist, und Achtung vor dem, was es
            werden kann.&quot;
          </h1>
        </div>
        <div className="flex flex-wrap py-8">
          <div className="w-full md:w-1/3 text-center flex flex-col text-green-200 mb-8">
            <h1 className="font-serif text-xl mb-4 text-white">Information</h1>
            <ul>
              <li>
                <Link to="/behandlungskonzept" className="underline font-thin">
                  Behandlungskonzept
                </Link>
              </li>
              {/* <li>
                <Link to="/finanzierung" className="underline font-thin">
                  Finanzierung
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="w-full md:w-1/3 text-center flex flex-col text-green-200 mb-8">
            <h1 className="font-serif text-xl mb-4 text-white">Kontakt</h1>
            <ul>
              <li>
                <a
                  href="tel:+49 163 392 93 42"
                  className="underline font-regular"
                >
                  +49 163 392 93 42
                </a>
              </li>
              <li>
                <a
                  href="mailto:marianne@mobile-heilpaedagogik.de"
                  className="underline font-thin"
                >
                  marianne@mobile-heilpaedagogik.de
                </a>
              </li>
              <li className="font-thin">Aurikelweg 19, 53840 Troisdorf</li>
            </ul>
          </div>
          <div className="w-full md:w-1/3 text-center flex flex-col text-green-200 mb-8">
            <h1 className="font-serif text-xl mb-4 text-white">Leistungen</h1>
            <ul>
              <li>
                <Link to="/beratung-diagnostik" className="underline font-thin">
                  Beratung und Diagnostik
                </Link>
              </li>
              <li>
                <Link
                  to="/unterstützung-förderung"
                  className="underline font-thin"
                >
                  Unterstützung und Förderung
                </Link>
              </li>
            </ul>
            <p className="font-serif text-xs my-4 text-white">
              Meine heilpädagogische Arbeit richtet sich nach den gesetzlichen
              Grundlagen der §§ 113, 116 SGB IX in Verbindung mit §79 Abs. 1 und
              Abs. 2 SGB IX. Wenn Ihr Kind eine heilpädagogische Frühförderung
              braucht, dann wird ein Antrag zur Übernahme der Kosten an den LVR
              gestellt.
            </p>
          </div>
        </div>
        <hr className="h-px mt-6 bg-green-900 opacity-50 border-none" />

        <div className="flex flex-col items-center justify-between mt-6 md:flex-row px-4">
          <div>
            <Logo />
          </div>
          <div className="flex mt-4 md:m-0">
            <div className="-mx-4">
              <Link to="/impressum" className="px-4 text-sm">
                Impressum
              </Link>
              <Link to="/datenschutz" className="px-4 text-sm">
                Datenschutz
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
